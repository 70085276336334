import React from 'react'
import logo from '../../assets/img/logo.png'
import styled from 'styled-components'

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  color: black;
  margin-top: 20px;
  
  th {
    font-weight: bold;
  }
  
  tr:last-child {
    font-weight: bold;

  }
  
  th:first-child, td:first-child {
    text-align: left;
    width: 70%;
  }
  
  th, td {
  padding: 0 10px;
    border: 1px solid #929292;
    text-align: center;
  }
`

class OfferPrint extends React.Component<any, any> {
 render(): React.ReactNode {

   const getData = (p:any) => {

     return [...p.components.map(c => [c.name, (c.count || 1) * p.count, c.price * p.count]), ["Podsumowanie", p.count, p.components.reduce((a, b) => a+(b.price * p.count),0)]]
   }

   return (
     <div style={{display: this.props.visible?'block':'none', top: 0, left: 0, padding: '50px', width:'100%', height:'100%', boxSizing: 'border-box'}}>
       <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
       <div>
         <div>{new Date().toLocaleString()}</div>
         <div>{localStorage.getItem('username')}</div>
       </div>
       </div>
       <div style={{marginTop: '80px', width: '100%', textAlign: 'center', fontSize: '24px', fontWeight: 'bold'}}>
         Zamówienie {this.props.id}
       </div>
       <div style={{display: 'flex', marginTop: '40px', width: '100%', justifyContent: "space-between"}}>
       <div>
         <h5>Klient</h5>
         <div>{this.props.offer.name}</div>
         <div>{this.props.offer.email}</div>
         <div>{this.props.offer.phone}</div>
       </div>
         <div>
           <div>Data złożenia {new Date(Number(this.props.offer.createDate) * 1000).toLocaleString()}</div>
           <div>Usługi: {this.props.offer.measurement? "pomiar":""}{this.props.offer.measurement && this.props.offer.installation? ", montaż":this.props.offer.installation? "montaż":""}</div>
       </div>
     </div>
       <div style={{marginTop: '50px'}}>
         {this.props.offer.cart.projects.map(p => (
         <Table>
           <thead>
            <th>Nazwa</th>
            <th>Ilość</th>
            <th>Cena</th>
           </thead>
           <tbody>
           {getData(p).map(d=> <tr>
             {d.map(c => <td>{c}</td>)}
           </tr>)}
           </tbody>
         </Table>
           ))}
         {this.props.offer.notes && <div style={{marginTop: '50px'}}>
             <h5>Uwagi</h5>
             <div>{this.props.offer.notes}</div>
           </div>}
       </div>
     </div>
   );
 }
};

export interface IOfferPrint {
ref: any
}

export default OfferPrint